<template>
  <div
    v-if="!menuList || menuList.length === 0"
    class="v-d-flex v-flex-row v-flex-100 v-menu-title v-error-color v-title"
  >
    <span
      v-if="FilterTags.length > 0 || FilterStickers.length > 0"
      v-html="translate('productInListPage.notFoundFilter')"
    />
    <span
      v-else-if="ActivityMapLoaded"
      v-html="translate('productInListPage.notFound')"
    />
    <arora-loader v-else />
  </div>
  <common-cards-flex-mesh
    v-else
    :class-name="`v-card-product-${appConfig.VueSettingsPreRun.MenuItemsInRow}`"
    :items="menuList"
    :max-items="appConfig.VueSettingsPreRun.MenuItemsInRow"
    :mesh-gap="appConfig.VueSettingsPreRun.MenuMeshGap"
    v-model:adaptive-items="adaptiveItems"
  >
    <template #indexedItem="{ item, index }">
      <menu-product-in-list-banner-card
        v-if="item.Banners && item.Banners.length > 0"
        :banners="item.Banners"
      />
      <menu-product-in-list-item-card-by-type
        v-else-if="item.Product"
        :key="`list-fanuidhol-${item.Product.ID}`"
        :disable-lazy="index <= appConfig.VueSettingsPreRun.MenuItemsInRow"
        :product="item.Product"
      />
    </template>
  </common-cards-flex-mesh>
</template>

<script setup lang="ts">
import type { BannerInList, ItemInList } from '~types/menuStore'
import type { MenuList } from '~types/props'

import { useCommon } from '@arora/common'

import { Guid } from '~api/consts'

const { groupId, subgroupId } = defineProps<MenuList>()

const { applyProductsFilter } = useProduct()
const { checkGuidEnable } = useCommon()

const appConfig = useAppConfig()
const menuStore = useMenuStore()
const router = useRouter()
const { ActivityMapLoaded, FilterStickers, FilterTags, Sorting } = storeToRefs(menuStore)
const adaptiveItems = ref<number>(appConfig.VueSettingsPreRun.MenuItemsInRow)

menuStore.CurrentItemsInRow = adaptiveItems.value

const { translate } = useI18nSanitized()
watch(
  () => adaptiveItems.value,
  () => {
    menuStore.CurrentItemsInRow = adaptiveItems.value
  }
)

const menuList = shallowRef<ItemInList[]>(
  appConfig.ProductsPerGroup[subgroupId ?? groupId] ?? ([] as ItemInList[])
)

function refresh(forceRefresh?: boolean): void {
  const newListValue = refreshProducts()

  if (forceRefresh || newListValue.length !== menuList.value.length) {
    menuList.value = newListValue
  }
}

watch(
  () => groupId,
  (newValue, oldValue) => {
    if (newValue !== oldValue) refresh()
  }
)
watch(
  () => subgroupId,
  (newValue, oldValue) => {
    if (newValue && newValue !== oldValue) refresh()
  }
)

watch(Sorting, (newValue, oldValue) => {
  if (newValue !== oldValue) refresh(true)
})

watch(FilterStickers, () => refresh(), { deep: true })

watch(FilterTags, () => refresh(), { deep: true })

watch(
  () => ActivityMapLoaded.value,
  () => refresh()
)

const removeRouterCheck = router.afterEach(() => refresh())

onMounted(() => {
  refresh()
})

onUnmounted(() => {
  removeRouterCheck()
})

function refreshProducts(): ItemInList[] {
  const currentGroupId = subgroupId ?? groupId
  if (Guid.IsNullOrEmpty(currentGroupId)) {
    return [] as ItemInList[]
  }
  const menuListItems = appConfig.ProductsPerGroup[currentGroupId] ?? ([] as ItemInList[])

  for (const banner of appConfig.Banners) {
    if (
      checkGuidEnable(banner.Active) &&
      (banner.GroupIDs.includes(currentGroupId) || banner.ShowInEveryGroup)
    ) {
      if (banner.PositionInGroup >= menuListItems.length) {
        menuListItems.push({ Banners: [banner] })
      } else if ((menuListItems[banner.PositionInGroup].Banners ?? []).length > 0) {
        menuListItems[banner.PositionInGroup].Banners!.push(banner)
      } else {
        const result = [] as BannerInList[]
        result.push(banner)
        menuListItems.splice(banner.PositionInGroup, 0, { Banners: result })
      }
    }
  }

  if (menuListItems.length > 0) {
    const items = menuStore.applyFiltersAndSorting(menuListItems)

    if (items.length === 0 && menuListItems.length > 0)
      console.error('No products available due to filters and sorting')

    const result = items.filter((item) => {
      if (item.Product) {
        return applyProductsFilter(item.Product)
      }
      return true //do not filter banners
    })

    return result
  }

  return [] as ItemInList[]
}
</script>
