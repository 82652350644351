<template>
  <div class="v-menu-banner-wrapper">
    <arora-carousel
      v-if="banners.length > 0"
      loop
      :items="banners"
      :autoplay-delay="5000"
      :max-items="1"
      pagination-type="none"
    >
      <template #item="item">
        <arora-nuxt-link
          v-if="item.CustomHref"
          class-name="v-menu-banner v-pointer"
          external-link
          open-in-new-tab
          :href="item.CustomHref"
          :label="item.Title ?? ''"
        >
          <arora-nuxt-image
            v-if="isExtraSmall && imageExists(item.DesktopMobileImage)"
            :key="`${item.ID}-custom-small`"
            disable-resize
            :alt="item?.Title ?? ''"
            :image="item.DesktopMobileImage"
          />
          <arora-nuxt-image
            v-else
            :key="`${item.ID}-custom-big`"
            disable-resize
            :alt="item?.Title ?? ''"
            :image="item.DesktopImage"
          />
        </arora-nuxt-link>
        <div
          v-else
          class="v-menu-banner"
        >
          <arora-nuxt-image
            v-if="isExtraSmall && imageExists(item.DesktopMobileImage)"
            :key="`${item.ID}-small`"
            disable-resize
            :alt="item?.Title ?? ''"
            :image="item.DesktopMobileImage"
          />
          <arora-nuxt-image
            v-else
            :key="`${item.ID}-big`"
            disable-resize
            :alt="item?.Title ?? ''"
            :image="item.DesktopImage"
          />
        </div>
      </template>
    </arora-carousel>
  </div>
</template>

<script setup lang="ts">
import type { BannerInList } from '~types/menuStore'

import { useWindowSize } from '@arora/common'

defineProps<{
  banners: BannerInList[]
}>()

const { isExtraSmall } = useWindowSize()
const { imageExists } = useImageInfo()
</script>

<style lang="scss">
@use 'assets/variables';

.v-menu-banner-wrapper {
  height: 100%;
  width: 100%;

  .carousel {
    height: 100%;
    width: 100%;
    margin: 0;
    border-radius: variables.$BorderRadius;

    &__track {
      margin: 0;

      a {
        height: 100%;
        width: 100%;
      }

      .v-img-fluid {
        border-radius: variables.$BorderRadius;
        object-fit: cover;
        width: 100%;
        height: auto;
      }
    }
  }
}
</style>
